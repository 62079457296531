.dashboard_container {
    background-image: url("./image/background.jpg");
    background-position: top center;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
}

.dashboard_header {
    width: 100%;
    height: 202px;
    display: flex;
    position: absolute;
}

.dashboard_header_button {
    display: flex;
    width: 300px;
    height: 105px;
    padding-top: 15px;
}

.dashboard_header_button_item {
    color: #ffffff;
    font-size: 20px;
    font-weight: bold;
    margin-left: 20px;
}

.dashboard_header_title {
    flex: 1;
    height: 60px;
    color: #ffffff;
    font-size: 28px;
    font-weight: bold;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.dashboard_header_time {
    width: 300px;
    height: 60px;
    padding-top: 3px;
    color: #ffffff;
    font-size: 16px;
    font-weight: bold;
    margin-right: 20px;
    text-align: right;
}

.dashboard_content {
    width: 100%;
    height: 100%;
    display: flex;
    margin-top: 60px;
}

.dashboard_content_col_0 {
    width: 430px;
    margin-left: 20px;
    margin-top: 20px;
}

.dashboard_content_col_1 {
    flex: 1;
    margin-left: 20px;
    margin-top: 20px;
}

.dashboard_content_col_2 {
    width: 430px;
    margin-left: 20px;
    margin-right: 20px;
    margin-top: 20px;
}

.dashboard_content_col_3 {
    width: 300px;
    margin-left: 20px;
    margin-right: 20px;
    margin-top: 20px;
}

.dashboard_content_col_item_main {
    padding: 20px;
    color: #ffffff;
}

.dashboard_content_col_item_main_title {
    color: #ffffff;
    font-size: 14px;
    font-weight: bold;
}

.dashboard_content_col_item_main_content_text {
    font-size: 12px;
    color: #ffffff;
    text-align: center;
}

.dashboard_content_col_item_title {
    width: 100%;
    height: 33px;
    font-size: 18px;
    font-weight: bold;
    color: #ffffff;
    padding-left: 5px;
    padding-bottom: 5px;
}

.dashboard_content_col_item_0 {
    width: 100%;
    height: 330px;
    position: relative;
}

.dashboard_content_col_item_0_4 {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 32px;
    background-image: url("./image/border_4.jpg");
    background-repeat: repeat-x;
}

.dashboard_content_col_item_0_5 {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    width: 32px;
    background-image: url("./image/border_5.jpg");
    background-repeat: repeat-y;
}

.dashboard_content_col_item_0_6 {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 32px;
    background-image: url("./image/border_6.jpg");
    background-repeat: repeat-x;
}

.dashboard_content_col_item_0_7 {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 32px;
    background-image: url("./image/border_7.jpg");
    background-repeat: repeat-y;
}

.dashboard_content_col_item_0_0 {
    position: absolute;
    top: 0;
    left: 0;
    width: 34px;
    height: 91px;
    background-image: url("./image/border_0.jpg");
    background-repeat: no-repeat;
}

.dashboard_content_col_item_0_1 {
    position: absolute;
    top: 0;
    right: 0;
    width: 34px;
    height: 72px;
    background-image: url("./image/border_1.jpg");
    background-repeat: no-repeat;
}

.dashboard_content_col_item_0_2 {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 34px;
    height: 98px;
    background-image: url("./image/border_2.jpg");
    background-repeat: no-repeat;
}

.dashboard_content_col_item_0_3 {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 40px;
    height: 32px;
    background-image: url("./image/border_3.jpg");
    background-repeat: no-repeat;
}

.dashboard_content_col_item_1 {
    width: 100%;
    height: 430px;
    position: relative;
}

.dashboard_content_col_item_2 {
    margin-top: 20px;
    width: 100%;
    height: 170px;
}

.dashboard_content_col_item_3 {
    width: 100%;
    height: 397px;
    margin-bottom: 10px;
}

.dashboard_content_col_item_3_0 {
    text-align: center;
    font-size: 18px;
    font-weight: bold;
    padding-top: 90px;
    color: #ffffff;
}

.dashboard_content_col_item_3_1 {
    margin-top: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.dashboard_content_col_item_3_1_0 {
    margin: 0 10px;
    width: 50px;
    height: 66px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 45px;
    color: #ffffff;
    font-weight: bold;
}

.dashboard_content_col_item_3_1_1 {
    height: 66px;
    display: flex;
    align-items: flex-end;
    color: #ffffff;
    font-weight: bold;
}

.dashboard_content_col_item_3_2 {
    margin-top: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.dashboard_content_col_item_3_2_0 {
    width: 150px;
    text-align: center;
    margin-top: 20px;
}

.dashboard_content_col_item_3_2_0_0 {
    color: #ffffff;
    font-weight: bold;
    margin-right: 20px;
}

.dashboard_content_col_item_3_2_0_1 {
    font-size: 28px;
    color: #ffffff;
    font-weight: bold;
}

.dashboard_content_col_item_3_2_0_1_0 {
    font-size: 14px;
    margin-left: 8px;
}

.dashboard_content_col_item_0_main {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    margin-top: 20px;
    margin-left: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.dashboard_content_col_item_0_main_2 {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    margin-top: 20px;
    margin-left: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.dashboard_content_col_item_0_main_title {
    width: 100%;
    height: 30px;
    color: #ffffff;
    font-size: 15px;
    font-weight: bold;
    display: flex;
    align-items: center;
}

.dashboard_content_col_item_0_main_title_image {
    margin-right: 8px;
}

.dashboard_content_col_item_4 {
    width: 100%;
    height: 430px;
    display: flex;
}

.dashboard_content_col_item_4_0 {
    width: 240px;
    height: 430px;
    position: relative;
}

.dashboard_content_col_item_4_1 {
    height: 205px;
    position: relative;
}

.dashboard_content_col_item_4_2 {
    margin-top: 20px;
    height: 205px;
    position: relative;
}

.dashboard_content_col_item_5 {
    margin-top: 20px;
    width: 100%;
    height: 250px;
    position: relative;
}

.dashboard_content_col_item_7 {
    width: 100%;
    height: 330px;
    display: flex;
}

.dashboard_content_col_item_7_0 {
    width: 205px;
    height: 330px;
    position: relative;
}

.dashboard_content_col_item_7_1 {
    width: 205px;
    height: 155px;
    position: relative;
}

.dashboard_content_col_item_7_2 {
    margin-top: 20px;
    width: 205px;
    height: 155px;
    position: relative;
}

.dashboard_content_col_item_7_2_0 {
    font-size: 24px;
    color: #ffffff;
    font-weight: bold;
    margin-right: 30px;
    margin-top: 15px;
}

.dashboard_content_col_item_8 {
    width: 100%;
    height: 205px;
    position: relative;
}

.dashboard_content_col_item_9 {
    margin-top: 20px;
    width: 100%;
    height: 205px;
    position: relative;
}

.dashboard_content_col_item_10 {
    width: 100%;
    height: 250px;
}

.dashboard_content_col_item_11 {
    margin-top: 20px;
    width: 100%;
    height: 250px;
}

.dashboard_content_col_item_12 {
    margin-top: 20px;
    width: 100%;
    height: 250px;
}

.dashboard_carousel {
    width: 100%;
    height: 180px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.dashboard_list_item_0 {
    width: 100%;
    height: 36px;
    background-color: #1370fb;
    display: flex;
}

.dashboard_list_item_1 {
    width: 100%;
    height: 36px;
    background-color: rgba(0, 44, 81, 0dashboard_8);
    display: flex;
}

.dashboard_list_item_2 {
    width: 100%;
    height: 36px;
    background-color: rgba(10, 29, 50, 0dashboard_8);
    display: flex;
}

.dashboard_list_item_col {
    flex: 1;
    height: 36px;
    line-height: 36px;
    font-size: 12px;
    color: #ffffff;
    text-align: center;
}

.dashboard_empty {
    width: 100%;
    height: 20px;
}
