html {
    width: 100%;
    height: 100%;
    background-color: #ffffff;
}

body {
    width: 100%;
    height: 100%;
    margin: 0;
    font-family: Inter, Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 16px;
    --color-bg-1: #f2f3f5;
    background-color: var(--color-neutral-2);
}

figure {
    margin: 0 auto !important;
}

figure img {
    display: block !important;
    margin: 0 !important;
}

#root {
    width: 100%;
    height: 100%;
    overflow: hidden;
}

#container {
    width: 100%;
    height: 100%;
}

#container > div {
    width: 100%;
    height: 100%;
}

.ka-wrapper {
    width: 100%;
    height: 100%;
}

.ka-content {
    width: 100%;
    height: 100%;
    position: relative;
}

.tabs {
    flex: 1;
    z-index: 10;
    position: relative;
}

.tabs .arco-tabs {
    position: absolute;
    bottom: -1px;
    left: 0px;
    right: 0px;
}

.tabs .arco-tabs-header-nav::before {
    height: 0px !important;
}

.tabs .arco-tabs-header-title {
    background-color: var(--color-menu-light-bg);
    border: 1px solid var(--color-border);
}

.tabs .arco-tabs-header-title:hover {
    background-color: var(--color-neutral-2);
}

.tabs .arco-tabs-header-title-active {
    background-color: var(--color-neutral-2);
    border-bottom-color: var(--color-neutral-2);
}

.tabs .arco-tabs-header-title-active:hover {
    background-color: var(--color-neutral-2);
    border-bottom-color: var(--color-neutral-2);
}

.tabs .arco-tabs-header-nav-card-gutter .arco-tabs-header-title-active,
.arco-tabs-header-nav-card-gutter .arco-tabs-header-title-active:hover {
    background-color: var(--color-neutral-2);
    border-bottom-color: var(--color-neutral-2);
}

.tabs .arco-tabs-header-title {
    padding-top: 18px !important;
    padding-bottom: 18px !important;
    padding-left: 22px !important;
    padding-right: 20px !important;
}

.tabs_left {
    width: 500px;
    display: flex;
    align-items: center;
}

.tabs_center {
    flex: 1;
    z-index: 10;
    position: relative !important;
    margin-bottom: 0px;
    display: flex;
    align-items: flex-end;
    justify-content: center;
}

.tabs_center .arco-tabs-header-nav {
    bottom: 0px;
}

.tabs_center .arco-tabs-header-title {
    /* padding-bottom: 15px !important; */
    height: 59px;
}

.tabs_center .arco-tabs-header-nav::before {
    background-color: transparent !important;
}

.tabs_center .arco-tabs-header-wrapper {
    justify-content: center;
}

.tabs_right {
    width: 500px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-right: 15px;
}

.card .arco-card-header {
    border-bottom-width: 0px !important;
}

.arco-card-size-default .arco-card-body {
    padding: 0px 16px 5px 16px !important;
}

.arco-breadcrumb-item {
    padding: 0px;
}

.arco-pagination {
    flex-wrap: wrap;
}

.arco-pagination-option {
    flex-shrink: 0;
}

.arco-modal-header {
    border-bottom-color: var(--color-border) !important;
}

.arco-modal-title {
    display: flex;
    align-items: center;
}

.arco-modal-title-text {
    flex: 1;
    text-align: left;
}

.arco-modal-title-button {
    color: var(--color-text-2) !important;
    display: flex;
    align-items: center;
    justify-content: center;
}

.arco-modal-title .arco-icon {
    margin-right: 0px !important;
}

.arco-modal-footer {
    border-top-color: var(--color-border) !important;
}

.arco-modal-content {
    padding: 0px !important;
    background-color: var(--color-neutral-2);
}

.arco-modal-simple .arco-modal-content {
    background-color: transparent !important;
}

.arco-upload-trigger-drag {
    width: auto !important;
    border-width: 0px !important;
    background-color: var(--color-bg-2) !important;
}

.arco-upload-trigger-drag:hover {
    background-color: var(--color-fill-3) !important;
}

.arco-upload-list-type-text .arco-upload-list-item:first-of-type,
.arco-upload-list-type-picture-list .arco-upload-list-item:first-of-type {
    margin-top: 12px !important;
}

.arco-form-item-control-children {
    word-wrap: break-word;
}

.arco-menu {
    height: 100%;
}

.arco-menu-vertical .arco-menu-inner {
    padding: 8px 8px !important;
}

.arco-tree-node {
    align-items: center !important;
}

.arco-picker {
    width: 100%;
}

.arco-input-group-addafter {
    padding: 0px !important;
    border-width: 0px !important;
}

.arco-input-group-addafter .arco-btn-size-default {
    border-radius: 0px !important;
}

.arco-upload-list-item-picture {
    display: flex;
    align-items: center;
    justify-content: center;
}

.arco-upload-list-item-picture img {
    height: auto !important;
}

.arco-modal-wrapper {
    z-index: 101;
}

.arco-card-actions {
    margin-top: 0px !important;
}

.arco-modal-mask {
    z-index: 101;
}

.arco-tabs-content {
    padding-top: 0px !important;
}

.layout {
    width: 100%;
    height: 100%;
    display: flex;
}

.header {
    width: 100%;
    height: 59px;
    flex-shrink: 0;
    display: flex;
    background-color: var(--color-menu-light-bg);
    border-bottom: 1px solid var(--color-border);
}

.help {
    flex-shrink: 0;
    margin-right: 16px;
    margin-top: 5px;
}

.help_icon {
    width: 20px;
    height: 20px;
    margin-top: 5px;
}

.help_icon > path {
    fill: var(--color-text-2);
}

.help_check {
    width: 14px;
}

.content {
    flex: 1;
    display: flex;
    flex-direction: column;
    background-color: var(--color-neutral-2);
}

.sider {
    width: 240px;
    height: 100%;
    flex-shrink: 0;
    background-color: var(--color-menu-light-bg);
    display: flex;
    flex-direction: column;
    transition: width ease-in-out 0.3s;
}

.title {
    /* height: 59px; */
    margin-top: 17px;
    flex-shrink: 0;
    display: flex;
    align-items: center;
    /* border-bottom: 1px solid var(--color-border); */
    padding: 0px 20px 0px 18px;
}

.title_mobile {
    flex-shrink: 0;
    display: flex;
    align-items: center;
    padding: 15px 20px;
    background-color: var(--color-menu-light-bg);
}

.title_text {
    font-size: 20px;
    font-weight: 600;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    color: var(--color-text-1);
    flex: 1;
    cursor: pointer;
}

.title_menu {
    margin-left: 24px;
    color: var(--color-text-1);
}

.menu {
    flex: 1;
}

.menu_mobile {
    height: 100%;
}

.menu_row {
    display: flex;
    align-items: center;
}

.menu_icon {
    margin-right: 6px !important;
}

.modal {
    margin: 0px 20px;
}

.index {
    position: relative;
    width: 100%;
    background-color: var(--color-bg-1);
}

.detail {
    position: absolute;
    z-index: 100;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    background-color: var(--color-bg-1);
    display: flex;
    flex-direction: column;
}

.scrollbar {
    overflow-x: hidden !important;
    background-color: var(--color-neutral-2);
    border-left: 1px solid var(--color-border);
    display: flex;
    flex-direction: column;
}

.breadcrumb {
    flex-shrink: 0;
    margin: 15px 20px;
}

.card {
    margin: 0px 20px 20px 20px;
}

.card_top {
    width: 100%;
    height: 20px;
}

.card_bottom {
    width: 100%;
    height: 30px;
    flex-shrink: 0;
}

.card_upload {
    margin: 0px 20px 20px 20px;
}

.card_upload .arco-card-body {
    padding: 0px !important;
    overflow: hidden;
}

.card_upload .arco-upload-list-item-text {
    background-color: var(--color-bg-2) !important;
}

.card_content {
    padding: 20px 0px;
}

.card_table {
    padding: 5px 0px 20px 0px;
}

.card_table_action {
    padding: 0px 0px 20px 0px;
}

.card_table_pagination {
    padding: 20px 0px 5px 0px;
}

.card_tree {
    padding: 10px 0px 5px 0px;
}

.action {
    margin: 0px 0px 15px 0px;
}

.action_detail {
    margin: 0px 0px 5px 0px;
}

.tag {
    margin: 0px 0px 15px 0px;
}

.table {
    flex: 1;
}

.pagination {
    margin: 20px 0px 8px 0px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}

.pagination_selected {
    flex: 1;
}

.loading {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    z-index: 999;
}

.loading_mask {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 100;
    background-color: var(--color-spin-layer-bg);
}

.loading_icon {
    z-index: 200;
    font-size: 32px !important;
}

.warning {
    padding: 8px 0px 4px 0px;
}

.warning_item {
    display: flex;
}

.warning_circle {
    width: 4px;
    height: 4px;
    margin-right: 8px;
    margin-top: 10px;
    border-radius: 50%;
    background-color: rgb(var(--primary-6));
    flex-shrink: 0;
}

.warning_text {
    flex: 1;
    color: #767e89;
    line-height: 25px;
}

.warning_text_active {
    cursor: pointer;
    color: rgb(var(--primary-6));
}

.process_title {
    line-height: 30px;
    font-weight: 500;
    color: rgb(var(--primary-6));
    cursor: pointer;
}

.process_time {
    color: #767e89;
}

.notfound {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.login {
    display: flex;
    align-items: center;
    justify-content: center;
}

.login_content {
    width: 100%;
    min-width: 360px;
    max-width: 420px;
    padding: 20px 20px;
    box-sizing: border-box;
}

.login_help {
    position: absolute;
    top: 16px;
    right: 16px;
}

.login_card {
    padding: 40px 20px;
    border-radius: 4px;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.08);
}

.login_title {
    color: var(--color-text-1);
    font-size: 22px;
    font-weight: 600;
    margin-bottom: 20px;
}

.login_submit {
    margin-top: 0px;
}

.design {
    width: 100%;
    min-width: 1200px;
    height: 100%;
    display: flex;
}

.design_container {
    flex: 1;
    height: 100%;
    min-width: 1200px;
    display: flex;
    flex-wrap: wrap;
}

.form_left {
    width: 300px;
    height: 100%;
    overflow: hidden;
    background-color: var(--color-bg-2);
    margin-right: 20px;
    border-right: 1px solid var(--color-neutral-3);
}

.form_center {
    flex: 1;
    min-width: 430px;
    min-height: 100%;
    overflow: hidden;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.form_center .arco-col-12 {
    width: 100%;
    padding: 20px !important;
}

.form_center_header {
    flex-shrink: 0;
    margin: 15px 0px 20px 0px;
    box-sizing: border-box;
}

.form_center_form {
    flex: 1;
    overflow-x: hidden;
    overflow-y: auto;
    margin: 0px 0px 20px 0px;
    box-sizing: border-box;
}

.form_right {
    width: 300px;
    height: 100%;
    background-color: var(--color-bg-2);
    /* overflow-y: auto; */
    position: relative;
    margin-left: 20px;
    border-left: 1px solid var(--color-neutral-3);
}

.form_right_form {
    margin: 15px 0px;
    box-sizing: border-box;
    width: auto !important;
}

.form_right_title {
    color: var(--color-text-1);
    display: flex;
    align-items: center;
}

.form_right_title_icon {
    font-size: 20px !important;
}

.form_right_title_text {
    font-weight: 600;
    font-size: 16px;
}

.form_right_label {
    font-weight: 600;
    color: var(--color-text-1);
}

.form_right_card {
    position: relative;
    margin-bottom: 16px;
}

.form_right_card:hover .form_right_card_button {
    display: block;
}

.form_right_card_button {
    display: none;
    position: absolute;
    bottom: 0px;
    right: 0px;
}

.form_right_condition {
    padding: 10px;
    background: var(--color-bg-2);
    margin-bottom: 10px;
}

.form_group {
    font-weight: 600;
    color: var(--color-text-1);
    margin: 15px 0px 0px 0px;
}

.form_group_widget {
    margin: 15px 0px 30px 0px;
}

.form_widget {
    color: var(--color-text-2);
    background-color: var(--color-secondary);
    padding: 0 15px;
    font-size: 14px;
    height: 32px;
    line-height: 32px;
    border-radius: var(--border-radius-small);
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
}

.form_widget:hover {
    color: var(--color-text-2);
    background-color: var(--color-secondary-hover);
}

.form_widget_text {
    margin-left: 5px;
}

.form {
    position: relative;
    padding: 15px 20px 20px 20px;
    background-color: var(--color-bg-2);
    border: 1px solid var(--color-bg-2);
}

.form .arco-form-item {
    margin-bottom: 0px !important;
}

.form_row {
    position: relative;
    padding: 20px;
    background-color: var(--color-bg-2);
    border: 1px dashed transparent;
}

.form_row_active {
    position: relative;
    padding: 20px;
    background-color: var(--color-bg-2);
    border: 1px solid rgb(var(--primary-6));
}

.form_card {
    position: relative;
    background-color: var(--color-bg-2);
    margin-bottom: 20px;
}

.form_card .arco-card-body {
    margin: 0px 20px 20px 20px;
    padding: 0px !important;
    border: 1px dashed var(--color-fill-3);
}

.form_card .arco-card-bordered {
    border: 1px solid transparent;
}

.form_card_active {
    position: relative;
    background-color: var(--color-bg-2);
    margin-bottom: 20px;
}

.form_card_active .arco-card-bordered {
    border: 1px solid rgb(var(--primary-6));
}

.form_card_active .arco-card-body {
    margin: 0px 20px 20px 20px;
    padding: 0px !important;
    border: 1px dashed var(--color-fill-3);
}

.form_active {
    position: relative;
    padding: 15px 20px 20px 20px;
    background-color: var(--color-bg-2);
    border: 1px solid rgb(var(--primary-6));
}

.form_active .arco-form-item {
    margin-bottom: 0px !important;
}

.form_item {
    pointer-events: none;
}

.form_component {
    width: 100%;
}

.design_card {
    position: relative;
    background-color: var(--color-bg-2);
    border: 1px dashed var(--color-border);
}

.design_card:hover {
    background-color: var(--color-secondary-hover);
}

.design_card .arco-card {
    background-color: transparent !important;
}

.design_card .arco-card-body {
    padding: 0px 20px 20px 20px !important;
}

.design_card_active {
    position: relative;
    background-color: var(--color-secondary-hover) !important;
    border: 1px solid rgb(var(--primary-6)) !important;
}

.design_card_active .arco-card {
    background-color: transparent !important;
}

.design_card_active .arco-card-body {
    padding: 0px 20px 20px 20px !important;
}

.design_layout {
    position: relative;
    padding: 20px;
    background-color: var(--color-bg-2);
    border: 1px dashed var(--color-border);
}

.design_layout .arco-row {
    margin-left: 0px !important;
    margin-right: 0px !important;
}

.design_layout .arco-col {
    padding-left: 20px !important;
    padding-right: 20px !important;
}

.design_layout_active {
    position: relative;
    padding: 20px;
    background-color: var(--color-secondary-hover) !important;
    border: 1px solid rgb(var(--primary-6)) !important;
}

.design_layout_active .arco-row {
    margin-left: 0px !important;
    margin-right: 0px !important;
}

.design_layout_active .arco-col {
    padding-left: 20px !important;
    padding-right: 20px !important;
}

.design_layout:hover {
    background-color: var(--color-secondary-hover);
}

.design_button {
    position: absolute;
    bottom: 0px;
    right: 0px;
}

.design_button .arco-divider-vertical {
    margin: 0 !important;
}

.design_button_item {
    width: 30px;
    height: 30px;
    line-height: 30px;
    text-align: center;
    color: #ffffff;
    font-size: 13px;
    cursor: pointer;
    float: right;
}

.design_button_item:hover {
    background-color: rgb(var(--primary-5)) !important;
}

.design_permission {
    position: absolute;
    top: 0px;
    left: 0px;
    bottom: 0px;
    right: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.design_permission_mask {
    position: absolute;
    top: 0px;
    left: 0px;
    bottom: 0px;
    right: 0px;
    background-color: var(--color-secondary-hover);
    opacity: 0.5;
}

.form_empty {
    position: absolute;
    top: 50%;
    margin-top: -50px;
    left: 50%;
    margin-left: -28px;
}

.input_icon {
    color: var(--color-text-2);
    font-size: 12px;
}

.react-flow__panel.right {
    display: none;
}

.react-flow__handle {
    background-color: transparent;
    border-width: 0;
}

.react-flow__controls-button {
    background: var(--color-fill-2) !important;
    border-bottom-width: 0px !important;
}

.flow_node {
    width: 240px;
    overflow: hidden;
    border-radius: 4px;
    border: 1px solid var(--color-border);
    box-shadow: 0 6px 10px var(--color-border);
    box-sizing: border-box;
    position: relative;
    overflow: hidden;
}

.flow_header {
    height: 45px;
    font-size: 15px;
    font-weight: 600;
    color: #ffffff;
    padding: 0 10px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid var(--color-border);
    box-sizing: border-box;
    cursor: pointer;
}

.flow_header_icon {
    font-size: 14px;
    margin-right: 5px;
}

.flow_header_text {
    width: 0;
    flex: 1;
}

.flow_header_condition_icon {
    font-size: 14px;
    margin-right: 5px;
    color: var(--color-text-1);
}

.flow_header_condition_text {
    width: 0;
    flex: 1;
    color: var(--color-text-1);
}

.flow_header_edit {
    margin-left: 10px;
}

.flow_main {
    font-size: 14px;
    padding: 0 10px;
    color: var(--color-text-1);
    background-color: var(--color-menu-light-bg);
    box-sizing: border-box;
    padding: 10px;
}

.flow_main_text {
    height: 30px;
    display: flex;
    align-items: center;
}

.flow_main_count {
    height: 20px;
    padding-top: 5px;
    display: flex;
    align-items: center;
}

.flow_delete {
    position: absolute;
    right: 0px;
    bottom: 0px;
    display: none;
}

.flow_node:hover .flow_delete {
    display: block;
}

.flow_condition_add_node {
    width: 240px;
    display: flex;
    justify-content: center;
    border: 1px solid transparent;
    box-sizing: border-box;
}

.flow_add_node {
    width: 240px;
    height: 33px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid transparent;
    box-sizing: border-box;
}

.flow_end_node {
    width: 240px;
    overflow: hidden;
    border-radius: 8px;
    border: 1px solid var(--color-border);
    box-shadow: 0 4px 10px #0c1f5014;
    box-sizing: border-box;
}

.flow_end_main {
    height: 45px;
    font-size: 15px;
    font-weight: 600;
    padding: 0 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--color-text-1);
    background-color: var(--color-menu-light-bg);
}

.flow_add_menu {
    display: flex;
    margin-top: 10px;
}

.flow_add_menu_empty {
    width: 10px;
}

.flow_add_menu_item {
    flex: 1;
    border: 1px solid var(--color-border);
    color: var(--color-text-1);
    background-color: var(--color-bg-2);
    overflow: hidden;
    border-radius: 4px;
    padding: 10px;
    display: flex;
    align-items: center;
    font-size: 14px;
    cursor: pointer;
}

.flow_add_menu_item_icon_0 {
    margin-right: 5px;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    border: 1px solid rgb(255, 148, 62);
    display: flex;
    align-items: center;
    justify-content: center;
    color: rgb(255, 148, 62);
}

.flow_add_menu_item_icon_1 {
    margin-right: 5px;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    border: 1px solid rgb(50, 150, 250);
    display: flex;
    align-items: center;
    justify-content: center;
    color: rgb(50, 150, 250);
}

.flow_add_menu_item_icon_2 {
    margin-right: 5px;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    border: 1px solid rgb(21, 188, 131);
    display: flex;
    align-items: center;
    justify-content: center;
    color: rgb(21, 188, 131);
}

.flow_add_menu_item_icon_3 {
    margin-right: 5px;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    border: 1px solid rgb(21, 188, 131);
    display: flex;
    align-items: center;
    justify-content: center;
    color: rgb(21, 188, 131);
}

.ck.ck-editor__top {
    display: none;
}

.ck.ck-input {
    border-color: rgb(var(--color-fill-2)) !important;
    background-color: var(--color-fill-2) !important;
    box-shadow: none !important;
    color: var(--color-text-1) !important;
}

.ck.ck-input:focus {
    border-color: rgb(var(--primary-6)) !important;
    background-color: var(--color-bg-2) !important;
    box-shadow: 0 0 0 0 var(--color-primary-light-2) !important;
}

.ck.ck-link-form {
    background: var(--color-bg-2) !important;
}

.ck.ck-responsive-form {
    background: var(--color-bg-2) !important;
}

.ck.ck-button {
    color: var(--color-text-2) !important;
    box-shadow: none !important;
}

.ck.ck-button.ck-on,
a.ck.ck-button.ck-on {
    background: var(--color-bg-2) !important;
    border-color: rgb(var(--primary-6)) !important;
}

.ck.ck-splitbutton.ck-splitbutton_open > .ck-button:not(.ck-on):not(.ck-disabled):not(:hover),
.ck.ck-splitbutton:hover > .ck-button:not(.ck-on):not(.ck-disabled):not(:hover) {
    background: var(--color-bg-2) !important;
}

.ck.ck-button:not(.ck-disabled):hover,
a.ck.ck-button:not(.ck-disabled):hover {
    background: var(--color-bg-2) !important;
}

.ck.ck-button:not(.ck-disabled):active,
a.ck.ck-button:not(.ck-disabled):active {
    background: var(--color-bg-2) !important;
    border-color: rgb(var(--primary-6)) !important;
    box-shadow: none !important;
}

.ck.ck-dropdown__panel {
    background: var(--color-bg-2) !important;
}

.ck.ck-toolbar {
    background: var(--color-fill-2) !important;
    border: 1px solid var(--color-neutral-3) !important;
}

.ck.ck-toolbar .ck.ck-toolbar__separator {
    background: var(--color-neutral-3) !important;
}

.ck.ck-list {
    background: var(--color-bg-2) !important;
}

.ck.ck-balloon-panel {
    border: 1px solid var(--color-neutral-3) !important;
    background: var(--color-fill-2) !important;
}

.ck.ck-editor__editable_inline.ck-blurred {
    background: var(--color-fill-2) !important;
    border: 1px solid var(--color-bg-2) !important;
    box-shadow: none !important;
}

.ck.ck-editor__editable.ck-focused:not(.ck-editor__nested-editable) {
    background: var(--color-bg-2) !important;
}

.ck.ck-content {
    background: var(--color-fill-2) !important;
    min-height: 200px;
    margin: 0 auto;
    margin-top: 10px;
    padding: var(--ck-spacing-large) !important;
    box-sizing: border-box;
}

.ck-source-editing-area {
    background: var(--color-fill-2) !important;
    min-height: 200px;
    margin: 0 auto;
    margin-top: 10px;
}

.ck-rounded-corners .ck-source-editing-area textarea {
    border-color: rgb(var(--primary-6)) !important;
    background-color: var(--color-bg-2) !important;
    color: var(--color-text-1) !important;
}

.ck-powered-by-balloon {
    display: none !important;
}

.editor_mobile .ck.ck-content {
    max-width: 430px !important;
}

.file_list {
    display: flex;
    flex-wrap: wrap;
    gap: 30px;
}

.file_item {
    position: relative;
    width: 82px;
    height: 82px;
    cursor: pointer;
}

.file_item_done {
    position: relative;
    width: 82px;
    height: 82px;
    cursor: pointer;
    overflow: hidden;
    background: var(--color-fill-2);
    display: flex;
    align-items: center;
    justify-content: center;
}

.file_item_done:hover .file_item_mask {
    display: block;
    opacity: 1;
}

.file_item_done:hover .file_item_button {
    display: flex;
}

.file_item_mask {
    display: none;
    position: absolute;
    z-index: 10;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    opacity: 0;
    transition: opacity 0.1s linear;
}

.file_item_button {
    position: absolute;
    z-index: 20;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    display: none;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    color: #ffffff;
}

.file_item_icon {
    margin-left: 6px;
    margin-right: 6px;
}

.file_item_error {
    position: relative;
    width: 82px;
    height: 82px;
    border: 1px solid rgb(var(--danger-6));
    cursor: pointer;
}

.file_item_retry_mask {
    position: absolute;
    z-index: 10;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
}

.file_item_retry_text {
    position: absolute;
    z-index: 20;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
}

.dashboard {
    margin: 0px 20px 20px 20px;
}

.dashboard_item {
    padding: 22px 16px 16px 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #ffffff;
}

.dashboard_item .arco-statistic-title {
    color: #ffffff !important;
    font-weight: 500;
}

.dashboard_item .arco-statistic-value-int {
    color: #ffffff !important;
}

.card-hover {
    cursor: pointer;
    transition-property: all;
}

.card-hover:hover {
    transform: translateY(-4px);
}

.responsible_title {
    display: flex;
    align-items: center;
}

.responsible_title_text {
    height: 30px;
    line-height: 30px;
    text-align: center;
    position: relative;
    cursor: pointer;
    margin-right: 20px;
    padding-top: 10px;
}

.responsible_title_text_active {
    height: 30px;
    line-height: 30px;
    text-align: center;
    position: relative;
    color: rgb(var(--primary-6));
    cursor: pointer;
    margin-right: 20px;
    padding-top: 10px;
}

.responsible_title_text_active::after {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0px;
    height: 2px;
    background: rgb(var(--primary-6));
}
